import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import App from "./App";
// import LoginForm from './components/SignUp';

ReactDOM.render(
  // <React.StrictMode>
  <div>
    <App />
  </div>,

  // </React.StrictMode>,
  document.getElementById("root")
);
