import Navbar from "./Navbar";

export default function Header() {
  return (
    <div>
      <div className="container p-2">
      
          <span>
            {" "}
            <Navbar />
          </span>
        
      </div>
    </div>
  );
}
